@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {

}

[data-sr-convertkit-subscribe-form] {
    @apply space-y-4;
}

[data-sr-input-label] {
    @apply block text-lg mb-1 text-white;
}

[data-sr-input] {
    @apply block text-white border-2 border-purple-900 w-full rounded-lg p-3 bg-stone-800;
}

[data-sr-button] {
    @apply flex text-white bg-gradient-to-r from-purple-700 to-pink-500 w-full rounded-lg p-3 font-bold uppercase text-sm leading-relaxed justify-center items-center;
}

[data-sr-button-icon] svg {
    width: 24px;
}

